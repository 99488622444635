const SpriteSheetName = "SpriteSheet1";
const BoyWithHat = {
  name: "boy_with_hat",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [1],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [0, 1, 2] },
  left: { repeat: -1, frameRate: 6, frames: [12, 13, 14] },
  right: { repeat: -1, frameRate: 6, frames: [24, 25, 26] },
  up: { repeat: -1, frameRate: 6, frames: [36, 37, 38] },
};

const GirlBigHair = {
  name: "girl_with_big_hair",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [49],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [48, 49, 50] },
  left: { repeat: -1, frameRate: 6, frames: [60, 61, 62] },
  right: { repeat: -1, frameRate: 6, frames: [72, 73, 74] },
  up: { repeat: -1, frameRate: 6, frames: [84, 85, 86] },
};

const GirlInRobe = {
  name: "girl_in_robe",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [4],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [3, 4, 5] },
  left: { repeat: -1, frameRate: 6, frames: [15, 16, 17] },
  right: { repeat: -1, frameRate: 6, frames: [27, 28, 29] },
  up: { repeat: -1, frameRate: 6, frames: [39, 40, 41] },
};

const GirlWithPigtails = {
  name: "girl_with_pigtails",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [52],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [51, 52, 53] },
  left: { repeat: -1, frameRate: 6, frames: [63, 64, 65] },
  right: { repeat: -1, frameRate: 6, frames: [75, 76, 77] },
  up: { repeat: -1, frameRate: 6, frames: [87, 88, 89] },
};
const GirlWithBlueHair = {
  name: "girl_with_blue_hair",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [7],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [7, 8, 9] },
  left: { repeat: -1, frameRate: 6, frames: [18, 19, 20] },
  right: { repeat: -1, frameRate: 6, frames: [30, 31, 32] },
  up: { repeat: -1, frameRate: 6, frames: [42, 43, 44] },
};
const ManInSuit = {
  name: "man_in_suit",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [55],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [54, 55, 56] },
  left: { repeat: -1, frameRate: 6, frames: [66, 67, 68] },
  right: { repeat: -1, frameRate: 6, frames: [78, 79, 80] },
  up: { repeat: -1, frameRate: 6, frames: [90, 91, 92] },
};
const GirlWithPurpleHair = {
  name: "girl_with_purple_hair",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [10],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [9, 10, 11] },
  left: { repeat: -1, frameRate: 6, frames: [21, 22, 23] },
  right: { repeat: -1, frameRate: 6, frames: [33, 34, 35] },
  up: { repeat: -1, frameRate: 6, frames: [45, 46, 47] },
};
const ManWithGlasses = {
  name: "man_with_glasses",
  spritesheetRef: SpriteSheetName,
  idle: {
    frames: [58],
    repeat: -1,
    frameRate: 1,
  },
  down: { repeat: -1, frameRate: 6, frames: [57, 58, 59] },
  left: { repeat: -1, frameRate: 6, frames: [69, 70, 71] },
  right: { repeat: -1, frameRate: 6, frames: [81, 82, 83] },
  up: { repeat: -1, frameRate: 6, frames: [93, 94, 95] },
};
module.exports.GetSpriteSheet = () => {
  return {
    name: SpriteSheetName,
    path: "assets/GK_Actor_1_T.png",
    height: 32,
    width: 32,
  };
};
module.exports.GetSprites = () => {
  return [
    BoyWithHat,
    GirlBigHair,
    GirlInRobe,
    GirlWithPigtails,
    GirlWithBlueHair,
    ManInSuit,
    GirlWithPurpleHair,
    ManWithGlasses,
  ];
};
