import { useEffect, useRef } from "react";
import Phaser from "phaser";
import NPCFactory from "../libs/NPCFactory";
const AnimatedTiles = require("phaser-animated-tiles/src/plugin/main");
const Utils = require("../libs/utils");
const faker = require("faker");

const feedback = [
  `I'm not happy here, I don't learn anything and I spent all day logging into GAIA`,
  `My manager sucks, people are promoted based on incompetance, maybe I should spend my time not working and get promoted too!`,
  `I didn't get any training all year and now my dog has more skills than me`,
  `I was promised a promotion for the last 4 years and didn't get it, not sure why I'm still here`,
  `I tried to ride the crypto markets and all I got was this rubbish t-shirt`,
  `If in doubt HODL`,
  `The office would be much better if I was paid in ETH`,
  `I've lost my dog, can you help me?`,
];
let debug = false;
Utils.ShuffleArray(feedback);

const config = {
  type: Phaser.AUTO, // Which renderer to use
  width: 1280, // Canvas width in pixels
  height: 720, // Canvas height in pixels
  parent: "game-container", // ID of the DOM element to add the canvas to
  pixelArt: true,
  physics: {
    default: "arcade",

    arcade: {
      gravity: { y: 0 }, // Top down game, so no gravity
      TILE_BIAS: 32,
      debug: debug,
    },
  },
  scale: {
    parent: "PARENT",
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,

    width: 720,
    height: 480,
    min: {
      width: 720,
      height: 480,
    },
  },
  plugins: {
    scene: [
      { key: "AnimatedTiles", plugin: AnimatedTiles, mapping: "animatedTiles" },
    ],
  },
  scene: [
    {
      preload: preload,
      create: create,
      update: update,
    },
  ],
};
let titleScreen;
let attractTitleTween;
let npcrules;
let map;
let npcFactory;
let player;
let playerSprite;
let cursors;
let camera;
let speed = 50;
let currentDialog;
let openingDialogVisible = false;
let openingTitleScreenVisible = true;
let openingDialog;
let NPCCollection = [];
let spacebarAnimation;
let spacebar;
const showSpacebarAnimation = (value) => {
  spacebarAnimation.visible = value;
};

function preload() {
  npcFactory = new NPCFactory(this);

  //this.load.image("officetiles", "assets/Main_Tilemap.png");

  this.load.image("DotoboriCityTileMap", "assets/DotoboriCityTileMap.png");
  this.load.image("KanagawaTileMap", "assets/KanagawaTileMap.png");
  this.load.image(
    "JapaneseCornerStoreTileMap",
    "assets/JapaneseCornerStoreTileMap.png"
  );
  this.load.image("OsakaTileMap", "assets/OsakaTileMap.png");
  this.load.image("TrainStationTileMap", "assets/TrainStationTileMap.png");
  this.load.image(
    "UrbanAccessoriesTileMap",
    "assets/UrbanAccessoriesTileMap.png"
  );
  this.load.image("ZenGardenTileMap", "assets/ZenGardenTileMap.png");

  this.load.image("npcrules", "assets/NPC.png");
  this.load.image("keyboard_space_down", "assets/keyB_Special_Space.png");
  this.load.image("keyboard_space_up", "assets/keyB_Special_Space_Up.png");

  this.load.image("keyboard_up_down", "assets/keyB_Direc_Up.png");
  this.load.image("keyboard_up_up", "assets/keyB_Direc_Up_Up.png");

  this.load.image("keyboard_down_down", "assets/keyB_Direc_Down.png");
  this.load.image("keyboard_down_up", "assets/keyB_Direc_Down_Up.png");

  this.load.image("keyboard_left_down", "assets/keyB_Direc_Left.png");
  this.load.image("keyboard_left_up", "assets/keyB_Direc_Left_Up.png");

  this.load.image("keyboard_right_down", "assets/keyB_Direc_Right.png");
  this.load.image("keyboard_right_up", "assets/keyB_Direc_Right_Up.png");

  this.load.image("FullScreenIcon", "assets/FullScreenIcon.png");

  this.load.image("TitleScreenLogo", "assets/title_screen_v2.png");

  this.load.tilemapTiledJSON("map", "assets/office-hero.json");
}

const closestTo = (target, x, y) => {
  if (Math.abs(target - x) < Math.abs(target - y)) return x;
  else return y;
};

function create() {
  npcFactory.LoadAnimations();
  playerSprite = npcFactory.GetUniquePlayerSprite();
  this.anims.create({
    key: "press_space",
    frames: [{ key: "keyboard_space_down" }, { key: "keyboard_space_up" }],
    frameRate: 10,
    repeat: -1,
  });
  this.anims.create({
    key: "press_up",
    frames: [{ key: "keyboard_up_down" }, { key: "keyboard_up_up" }],
    frameRate: 10,
    repeat: -1,
  });
  this.anims.create({
    key: "press_down",
    frames: [{ key: "keyboard_down_down" }, { key: "keyboard_down_up" }],
    frameRate: 10,
    repeat: -1,
  });
  this.anims.create({
    key: "press_left",
    frames: [{ key: "keyboard_left_down" }, { key: "keyboard_left_up" }],
    frameRate: 10,
    repeat: -1,
  });
  this.anims.create({
    key: "press_right",
    frames: [{ key: "keyboard_right_down" }, { key: "keyboard_right_up" }],
    frameRate: 10,
    repeat: -1,
  });
  var button = this.add
    .image(1280 - 16, 16, "FullScreenIcon", 0)
    .setOrigin(1, 0)
    .setScrollFactor(0)
    .setInteractive()
    .setDepth(2001);

  button.on(
    "pointerup",
    function () {
      if (this.scale.isFullscreen) {
        button.setFrame(0);

        this.scale.stopFullscreen();
      } else {
        button.setFrame(1);

        this.scale.startFullscreen();
      }
    },
    this
  );
  game.scale.fullScreenScaleMode = Phaser.ScaleManager;
  map = this.make.tilemap({ key: "map" });
  const DotoboriCityTileSet = map.addTilesetImage(
    "DotoboriCity",
    "DotoboriCityTileMap"
  );
  const JapaneseCornerStoreTileSet = map.addTilesetImage(
    "JapaneseCornerShop",
    "JapaneseCornerStoreTileMap"
  );
  const UrbanAccessoriesTileSet = map.addTilesetImage(
    "UrbanAccessories",
    "UrbanAccessoriesTileMap"
  );
  const KanagawaTileSet = map.addTilesetImage("Kanagawa", "KanagawaTileMap");
  const OsakaTileSet = map.addTilesetImage("Osaka", "OsakaTileMap");

  const TrainStationTileSet = map.addTilesetImage(
    "TrainStation",
    "TrainStationTileMap"
  );
  const ZenGardenTileSet = map.addTilesetImage("ZenGarden", "ZenGardenTileMap");

  const NPCRulesTileSet = map.addTilesetImage("NPC", "npcrules");
  const tilesets = [
    DotoboriCityTileSet,
    JapaneseCornerStoreTileSet,
    KanagawaTileSet,
    OsakaTileSet,
    TrainStationTileSet,
    ZenGardenTileSet,
    UrbanAccessoriesTileSet,
  ];

  map
    .createLayer("BG", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true });
  map
    .createLayer("L1", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true });
  const L2 = map
    .createLayer("L2", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true });

  map
    .createLayer("L3", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true });
  player = this.physics.add.sprite(
    1430,
    480,
    playerSprite.spritesheetRef,
    playerSprite.idle
  );
  player.depth = player.y;
  player.body.setSize(8, 1);
  player.body.offset.y = 32;
  player.debugShowBody = true;
  player.debugBodyColor = new Phaser.Display.Color(40, 39, 37, 255);
  //player.setCollideWorldBounds();
  map
    .createLayer("L4", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true })
    .setDepth(997);
  map
    .createLayer("L5", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true })
    .setDepth(998);
  map
    .createLayer("L6", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true })
    .setDepth(999);
  map
    .createLayer("L7", tilesets, 0, 0)
    .setCollisionByProperty({ collides: true })
    .setDepth(1000);
  npcrules = map
    .createLayer("NPC", NPCRulesTileSet, 0, 0)
    .setCollisionByProperty({ collides: true });
  npcrules.visible = debug;

  //Debug shader for colision tiles
  // eslint-disable-next-line
  const debugGraphics = this.add.graphics().setAlpha(0.75);
  // layer.renderDebug(debugGraphics, {
  //   tileColor: null, // Color of non-colliding tiles
  //   collidingTileColor: new Phaser.Display.Color(243, 134, 48, 255), // Color of colliding tiles
  //   faceColor: new Phaser.Display.Color(40, 39, 37, 255), // Color of colliding face edges
  // });
  // layer2.renderDebug(debugGraphics, {
  //   tileColor: null, // Color of non-colliding tiles
  //   collidingTileColor: new Phaser.Display.Color(243, 134, 48, 255), // Color of colliding tiles
  //   faceColor: new Phaser.Display.Color(40, 39, 37, 255), // Color of colliding face edges
  // });
  if (debug) {
    // npcrules.renderDebug(debugGraphics, {
    //   tileColor: null, // Color of non-colliding tiles
    //   collidingTileColor: new Phaser.Display.Color(243, 134, 48, 255), // Color of colliding tiles
    //   faceColor: new Phaser.Display.Color(40, 39, 37, 255), // Color of colliding face edges
    // });
  }
  this.sys.animatedTiles.init(map);
  this.sys.animatedTiles.setRate(0.5);
  npcrules.forEachTile((tile, index, tileArray) => {
    if (
      tile &&
      tile.properties["collides"] &&
      (tile.properties["collidemodleft"] ||
        tile.properties["collidemodright"] ||
        tile.properties["collidemodtop"] ||
        tile.properties["collidemodbottom"])
    ) {
      tile.setCollisionCallback((sprite, tile) => {
        if (tile.properties["collidemodleft"]) {
          let threshold = tile.pixelX + tile.properties["collidemodleft"];
          let PlayerPosition = Math.abs(sprite.body.right);
          if (PlayerPosition >= threshold && PlayerPosition <= tile.right) {
            sprite.body.x = threshold - sprite.body.width;
          }
        } else if (tile.properties["collidemodright"]) {
          let threshold = tile.right - tile.properties["collidemodright"];
          let PlayerPosition = Math.abs(sprite.body.left);
          if (PlayerPosition <= threshold && PlayerPosition >= tile.pixelX) {
            sprite.body.x = threshold;
          }
        } else if (tile.properties["collidemodtop"]) {
          let threshold = tile.pixelY + tile.properties["collidemodtop"];
          let PlayerPosition = Math.abs(sprite.body.bottom);
          if (PlayerPosition >= threshold && PlayerPosition <= tile.bottom) {
            const v = closestTo(PlayerPosition, tile.bottom, threshold);
            sprite.body.y = v;
          }
        } else if (tile.properties["collidemodbottom"]) {
          let threshold = tile.bottom - tile.properties["collidemodbottom"];
          let PlayerPosition = Math.abs(sprite.body.top);
          if (PlayerPosition <= threshold && PlayerPosition >= tile.pixelY) {
            const v = closestTo(PlayerPosition, tile.pixelY, threshold);
            sprite.body.y = v; //+ sprite.body.height;
          }
        } else {
          console.log("uncaught random problem");
          return false;
        }
        return true;
      });
    }
  });

  camera = this.cameras.main;
  cursors = this.input.keyboard.createCursorKeys();

  //NPC Sprites
  let NPCSpawLocations = [];
  npcrules.forEachTile(
    (tile, index, tileArray) => {
      // console.log(tile);
      if (tile.properties["npcspawn"] === true) {
        console.log("Found NPC Spawn Location", tile.x, tile.y);
        let world = L2.tileToWorldXY(tile.x, tile.y, undefined, camera);
        console.log("World spawn location", world);
        NPCSpawLocations.push({
          map: { x: tile.x, y: tile.y },
          world: { x: world.x, y: world.y },
        });
      }
    },
    undefined,
    0,
    0
  );

  NPCSpawLocations = Utils.ShuffleArray(NPCSpawLocations);
  feedback.forEach((fbi) => {
    const spawn = NPCSpawLocations.pop();
    const NPC = this.physics.add.sprite(
      spawn.world.x,
      spawn.world.y,
      "sprites1",
      51
    );
    NPC.animations = npcFactory.GetRandomSpriteAnimationSet();
    //Calculate end point for tween
    let res = findTweenEndPoint(spawn, NPC);
    if (res.movmentDirection === "horizontal") res.tile.x -= 16;
    else NPC.x += 16;
    NPC.npcTweenEnd = res.tile;
    NPC.npcSpeedMod = res.speedMod;
    NPC.startAnimation = res.startAnimation;
    NPC.endAnimation = res.endAnimation;
    NPC.movmentDirection = res.movmentDirection;
    NPC.npcTweenStart = { x: spawn.world.x, y: spawn.world.y }; //player is in center of tile so /2 to center NPC in world tile

    NPC.npcName = faker.name.findName();
    NPC.npcDialog = fbi;
    NPC.anims.play(NPC.animations.idle, true);
    NPC.depth = NPC.y;
    NPCCollection.push(NPC);
  });

  camera.setBounds(0, 0, map.widthInPixels, map.heightInPixels);
  camera.startFollow(player, true);

  // this.physics.add.collider(player, layer);
  // this.physics.add.collider(player, layer2);
  this.physics.add.collider(player, npcrules);

  spacebarAnimation = this.add
    .sprite(650, 400, "keyboard_space_up")
    .play("press_space")
    .setDepth(2002);
  showSpacebarAnimation(false);
  spacebarAnimation.displayWidth = 100;
  spacebarAnimation.scaleY = spacebarAnimation.scaleX;
  spacebarAnimation.setScrollFactor(0);

  let dialog = this.add
    .text(
      16,
      16,
      `Welcome to your world of feedback, explore at your own pace\nUse the arrow keys to move around and spacebar to interact with people\n(Spacebar to make this message go away!)`,
      {
        font: "18px monospace",
        fill: "#ffffff",
        lineSpacing: 1,
        padding: { x: 30, top: 20, bottom: 100 },
        backgroundColor: "#000000",
        wordWrap: { width: 690, useAdvancedWrap: true },
      }
    )
    .setScrollFactor(0)
    .setDepth(2000);
  let up = this.add
    .sprite(200, 120, "keyboard_up_up")
    .play("press_up")
    .setScrollFactor(0)
    .setDepth(2001);
  let left = this.add
    .sprite(160, 160, "keyboard_left_up")
    .play("press_left")
    .setScrollFactor(0)
    .setDepth(2001);
  let down = this.add
    .sprite(200, 160, "keyboard_down_up")
    .play("press_down")
    .setScrollFactor(0)
    .setDepth(2001);
  let right = this.add
    .sprite(240, 160, "keyboard_right_up")
    .play("press_right")
    .setScrollFactor(0)
    .setDepth(2001);
  let space = this.add
    .sprite(440, 160, "keyboard_space_down")
    .play("press_space")
    .setScrollFactor(0)
    .setDepth(2001);
  space.displayWidth = 100;
  space.scaleY = space.scaleX;
  openingDialog = { dialog, keys: { up, down, left, right, space } };

  titleScreen = this.add
    .sprite(360, 230, "TitleScreenLogo")
    .setScrollFactor(0)
    .setDepth(2001);
  showOpeningDialog(false);

  let attractScrollSpeed = 20000;
  attractTitleTween = this.tweens.createTimeline();
  attractTitleTween.loop = -1;
  attractTitleTween.yoyo = true;
  attractTitleTween.add({
    targets: camera,
    duration: attractScrollSpeed,
    scrollX: 0,
    onFinish: () => {
      camera.currentTween = undefined;
    },
  });
  attractTitleTween.add({
    targets: camera,
    duration: attractScrollSpeed,
    scrollY: 1000,
    onFinish: () => {
      camera.currentTween = undefined;
    },
  });
  attractTitleTween.add({
    targets: camera,
    duration: attractScrollSpeed,
    scrollX: 2500,
    onFinish: () => {
      camera.currentTween = undefined;
    },
  });
  attractTitleTween.add({
    targets: camera,
    duration: attractScrollSpeed,
    scrollY: 240,
    onFinish: () => {
      camera.currentTween = undefined;
    },
  });
  attractTitleTween.add({
    targets: camera,
    duration: attractScrollSpeed,
    scrollX: 1070,
    onFinish: () => {
      camera.currentTween = undefined;
    },
  });
  spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
}
const showOpeningDialog = (value) => {
  openingDialog.dialog.visible = value;
  openingDialog.keys.up.visible = value;
  openingDialog.keys.down.visible = value;
  openingDialog.keys.left.visible = value;
  openingDialog.keys.right.visible = value;
  openingDialog.keys.space.visible = value;
};

const findTweenEndPoint = (spawn, NPC) => {
  // Test which direction the path is

  let above = map.getTileAt(spawn.map.x, spawn.map.y - 1, "NPC");
  let below = map.getTileAt(spawn.map.x, spawn.map.y + 1, "NPC");
  let left = map.getTileAt(spawn.map.x - 1, spawn.map.y, "NPC");
  let right = map.getTileAt(spawn.map.x + 1, spawn.map.y, "NPC");
  //console.log({ spawntile, above, below, left, right });

  if (above && above.properties["npcmove"]) {
    console.log("Next move tile is Above");
    let i = 0;
    while (above.properties["npcmove"]) {
      i++;
      above = map.getTileAt(spawn.map.x, spawn.map.y - i, "NPC");
      above.alpha = 0.5; //debug
    }
    console.log("Futhest tile is", above);
    console.log("SpeedMod", i);
    let res = {
      speedMod: i,
      tile: npcrules.tileToWorldXY(above.x, above.y + 1, undefined, camera),
      startAnimation: NPC.animations.up,
      endAnimation: NPC.animations.down,
      movmentDirection: "vertical",
    }; //needed to + 1 tile here otherwise path is falling short by 32px
    return res;
  } else if (below && below.properties["npcmove"]) {
    console.log("Next move tile is Below");
    let i = 0;
    while (below.properties["npcmove"]) {
      i++;
      below = map.getTileAt(spawn.map.x, spawn.map.y + i, "NPC");
      below.alpha = 0.5; //debug
    }
    console.log("Futhest tile is", below);
    console.log("SpeedMod", i);
    let res = {
      speedMod: i,
      tile: npcrules.tileToWorldXY(below.x, below.y - 1, undefined, camera),
      startAnimation: NPC.animations.down,
      endAnimation: NPC.animations.up,
      movmentDirection: "vertical",
    }; //needed to + 1 tile here otherwise path is falling short by 32px
    return res;
  } else if (left && left.properties["npcmove"]) {
    console.log("Next move tile is Left");
    let i = 0;
    while (left.properties["npcmove"]) {
      i++;
      left = map.getTileAt(spawn.map.x - i, spawn.map.y, "NPC");
      left.alpha = 0.5; //debug
    }
    console.log("Futhest tile is left", left);
    console.log("SpeedMod", i);
    let res = {
      speedMod: i + 1,
      tile: npcrules.tileToWorldXY(left.x + 2, left.y, undefined, camera),
      startAnimation: NPC.animations.left,
      endAnimation: NPC.animations.right,
      movmentDirection: "horizontal",
    }; //needed to + 1 tile here otherwise path is falling short by 32px
    return res;
  } else if (right && right.properties["npcmove"]) {
    console.log("Next move tile is Right");
    let i = 0;
    while (right.properties["npcmove"]) {
      i++;
      right = map.getTileAt(spawn.map.x + i, spawn.map.y, "NPC");
      right.alpha = 0.5; //debug
    }
    console.log("Futhest tile is", right);
    console.log("SpeedMod", i);
    let res = {
      speedMod: i + 1,
      tile: npcrules.tileToWorldXY(right.x, right.y, undefined, camera),
      startAnimation: NPC.animations.right,
      endAnimation: NPC.animations.left,
      movmentDirection: "horizontal",
    }; //needed to + 1 tile here otherwise path is falling short by 32px

    return res;
  } else {
    console.log("Couldnt find a move tile");
    let res = {
      movmentDirection: "none",
    };
    return res;
  }
};

function update(time, delta) {
  // controls.update(delta);
  player.body.setVelocity(0);

  if (!openingTitleScreenVisible && openingDialogVisible) {
    showOpeningDialog(true);
  }

  if (openingTitleScreenVisible) {
    camera.stopFollow();
    // console.log(camera);
    if (!attractTitleTween.isPlaying()) attractTitleTween.play();
  }

  // Horizontal movement (Split vertical and horizontal into two if blocks so you can move left and up at the same time, but will break animation)
  if (cursors.left.isDown) {
    player.body.setVelocityX(-100);
    player.anims.play(playerSprite.left, true);
  } else if (cursors.right.isDown) {
    player.body.setVelocityX(100);
    player.anims.play(playerSprite.right, true);
  } else if (cursors.up.isDown) {
    player.body.setVelocityY(-100);
    player.anims.play(playerSprite.up, true);
  } else if (cursors.down.isDown) {
    player.body.setVelocityY(100);
    player.anims.play(playerSprite.down, true);
  }

  if (Phaser.Input.Keyboard.JustDown(spacebar)) {
    if (openingTitleScreenVisible) {
      openingTitleScreenVisible = false;
      titleScreen.visible = false;
      attractTitleTween.stop();
      camera.startFollow(player, true);
      showOpeningDialog(true);
    } else {
      openingDialogVisible = false;
      showOpeningDialog(false);
    }

    let NPC = nearNPC();
    if (NPC && !currentDialog) {
      //Show NPC Dialog
      currentDialog = this.add
        .text(16, 16, `You found ${NPC.npcName},\n${NPC.npcDialog}`, {
          font: "18px monospace",
          fill: "#ffffff",
          lineSpacing: 1,
          padding: { x: 30, y: 10 },
          backgroundColor: "#000000",
          wordWrap: { width: 680, useAdvancedWrap: true },
        })
        .setScrollFactor(0)
        .setDepth(2000);
    } else if (NPC && currentDialog) {
      console.log("Ignoring Press");
    }
  }

  if (
    cursors.up.isUp &&
    cursors.down.isUp &&
    cursors.left.isUp &&
    cursors.right.isUp
  ) {
    player.play(playerSprite.idle, true);
  }

  //NPC Movement Schedule
  let anyArePaused = false;
  NPCCollection.forEach((npc) => {
    //if (npc.movmentDirection === "none") return;

    if (npc.movmentDirection !== "none" && !npc.isMoving) {
      npc.npcTween = this.tweens.add({
        targets: npc,
        duration: npc.npcSpeedMod * 1000,
        x: () => {
          if (npc.movmentDirection === "horizontal")
            return npc.x === npc.npcTweenEnd.x
              ? npc.npcTweenStart.x
              : npc.npcTweenEnd.x;
          return npc.x;
        },
        y: () => {
          if (npc.movmentDirection === "vertical")
            return npc.y === npc.npcTweenEnd.y
              ? npc.npcTweenStart.y
              : npc.npcTweenEnd.y;
          return npc.y;
        },
        onStart: () => {
          let animation;
          if (npc.movmentDirection === "horizontal") {
            if (npc.x === npc.npcTweenEnd.x) {
              animation = npc.endAnimation;
            } else {
              animation = npc.startAnimation;
            }
          } else {
            if (npc.y === npc.npcTweenEnd.y) {
              animation = npc.endAnimation;
            } else {
              animation = npc.startAnimation;
            }
          }

          npc.npcResumeAnimation = animation;
          npc.anims.play(animation, true);
          npc.isMoving = true;
        },
        onComplete: () => {
          setTimeout(() => {
            npc.isMoving = false;
          }, 1000);
          npc.anims.play(npc.animations.idle, true);
        },
      });
    }

    if (isInsideBoundingBox(player, generateBoundingBox(npc))) {
      if (npc.movmentDirection !== "none") {
        npc.npcTween.pause();
      }
      npc.anims.play(npc.animations.idle, true);
      showSpacebarAnimation(true);
      anyArePaused = true;
    } else {
      if (npc.movmentDirection !== "none" && npc.npcTween.isPaused()) {
        npc.anims.play(npc.npcResumeAnimation, true);
        npc.npcTween.resume();
      }
    }

    if (npc.movmentDirection !== "none" && npc.npcTween.isPaused()) {
      anyArePaused = true;
    }
    npc.depth = npc.y + 32 / 2;
  });

  if (!anyArePaused) {
    showSpacebarAnimation(false);
    if (currentDialog) {
      console.log("Destroying current dialog because no NPCs are paused");
      currentDialog.destroy();
      currentDialog = undefined;
    }
  }
  // Normalize and scale the velocity so that player can't move faster along a diagonal
  player.body.velocity.normalize().scale(speed);
  player.depth = player.y + 32 / 2;
}
const nearNPC = () => {
  let result = NPCCollection.find((NPC) => {
    if (isInsideBoundingBox(player, generateBoundingBox(NPC))) {
      console.log("depth", NPC.depth);
      return NPC;
    }
    return undefined;
  });
  return result;
};
const generateBoundingBox = (NPC) => {
  return {
    top: NPC.body.top - 32,
    right: NPC.body.right + 32,
    bottom: NPC.body.bottom + 32,
    left: NPC.body.left - 32,
  };
};
const isInsideBoundingBox = (player, boundingBox) => {
  // console.log(boundingBox, {
  //   bottom: player.body.bottom,
  //   left: player.body.left,
  //   right: player.body.right,
  //   top: player.body.top,
  // });
  let result =
    player.body.left >= boundingBox.left &&
    player.body.right <= boundingBox.right &&
    player.body.top >= boundingBox.top &&
    player.body.bottom <= boundingBox.bottom;
  return result;
};
var game;
const PhaserWorld = () => {
  const gameContainer = useRef(null);
  useEffect(() => {
    config.parent = gameContainer.current;
    game = new Phaser.Game(config);
    console.log("Game is running", game.isRunning);
    return () => {
      // game.destroy();
    };
  }, [gameContainer]);
  return <div ref={gameContainer}></div>;
};
export default PhaserWorld;
