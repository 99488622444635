const Utils = require("./utils");
const SpriteSheet1 = require("./spritesheets/sprite1");
const SpriteSheet2 = require("./spritesheets/sprite2");

let Sprites = [];
let PreLoadAssets = [];
PreLoadAssets.push(SpriteSheet1.GetSpriteSheet());
PreLoadAssets.push(SpriteSheet2.GetSpriteSheet());
let sprites1 = SpriteSheet1.GetSprites();
let sprites2 = SpriteSheet2.GetSprites();
Sprites = [...sprites1, ...sprites2];
Sprites = Utils.ShuffleArray(Sprites);

export default class NPCFactory {
  constructor(gameRef) {
    this.GameRef = gameRef;
    this.Sprites = [];
    this.PreLoad();
  }

  LoadAnimations = () => {
    Sprites.forEach((sprite) => {
      console.log("Loading Sprite Animations:", sprite.name);
      this.GameRef.anims.create({
        key: `${sprite.name}_idle`,
        frames: this.GameRef.anims.generateFrameNumbers(sprite.spritesheetRef, {
          frames: sprite.idle.frames,
        }),
        frameRate: sprite.idle.frameRate,
        repeat: sprite.idle.repeat,
      });
      this.GameRef.anims.create({
        key: `${sprite.name}_down`,
        frames: this.GameRef.anims.generateFrameNumbers(sprite.spritesheetRef, {
          frames: sprite.down.frames,
        }),
        frameRate: sprite.down.frameRate,
        repeat: sprite.down.repeat,
      });
      this.GameRef.anims.create({
        key: `${sprite.name}_left`,
        frames: this.GameRef.anims.generateFrameNumbers(sprite.spritesheetRef, {
          frames: sprite.left.frames,
        }),
        frameRate: sprite.left.frameRate,
        repeat: sprite.left.repeat,
      });
      this.GameRef.anims.create({
        key: `${sprite.name}_right`,
        frames: this.GameRef.anims.generateFrameNumbers(sprite.spritesheetRef, {
          frames: sprite.right.frames,
        }),
        frameRate: sprite.right.frameRate,
        repeat: sprite.right.repeat,
      });
      this.GameRef.anims.create({
        key: `${sprite.name}_up`,
        frames: this.GameRef.anims.generateFrameNumbers(sprite.spritesheetRef, {
          frames: sprite.up.frames,
        }),
        frameRate: sprite.up.frameRate,
        repeat: sprite.up.repeat,
      });
      let spriteAnimationSet = {
        spritesheetRef: sprite.spritesheetRef,
        idle: `${sprite.name}_idle`,
        down: `${sprite.name}_down`,
        left: `${sprite.name}_left`,
        right: `${sprite.name}_right`,
        up: `${sprite.name}_up`,
      };
      this.Sprites.push(spriteAnimationSet);
    });
    console.log("Animation Load Finished");
  };
  GetUniquePlayerSprite = () => {
    return this.Sprites.pop();
  };
  GetRandomSpriteAnimationSet = () => {
    return this.Sprites[Math.floor(Math.random() * this.Sprites.length)];
  };
  PreLoad = () => {
    PreLoadAssets.forEach((preload) => {
      console.log("Loading Asset:", preload.name, preload.path);
      this.GameRef.load.spritesheet(preload.name, preload.path, {
        frameWidth: preload.width,
        frameHeight: preload.height,
      });
    });
    console.log("PreLoad Finished");
  };
}
