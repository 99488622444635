import PhaserWorld from "./components/phaserworld";
import "./App.css";

function App() {
  return (
    <div className="App">
      <PhaserWorld />
    </div>
  );
}

export default App;
